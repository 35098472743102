import { useMemo, useState } from 'react';
import clsx from 'clsx';
import './App.css';

function App() {
  const [mixer, setMixer] = useState<Record<audios, HTMLAudioElement>>();
  const [playing, setPlaying] = useState<audios>();

  useMemo(() => {
    setMixer({
      standup: new Audio('/standup.mp3'),
      explore: new Audio('/pittore-urla.mp3'),
      problems: new Audio('/problemi.mp3'),
      release: new Audio('/tututu.mp3'),
      invoices: new Audio('/fatture.mp3'),
      silvio: new Audio('/silvio.mp3'),
    })
  }, [])
  
  if (!mixer) return null;

  return (
    <div className='h-screen flex flex-col'>
      <a href='https://vas.it' target='_blank' rel="noreferrer">
        <img src="/vas.jpg" alt="Vas" className="w-screen h-auto" />
      </a>
      <div className="flex flex-col flex-grow items-center mt-10">
        <h1 className="text-3xl font-bold underline">
          Un suono per ogni occasione!
        </h1>
        <div className='flex flex-wrap gap-4 items-center justify-center mt-10'>
          <Button
            color='bg-gray-400'
            label='Silvio'
            isPlaying={playing === 'silvio'}
            onClick={() => {
              if (playing) mixer[playing].pause();
              if (playing === 'silvio') {
                setPlaying(undefined);
              } else {
                mixer.silvio.play();
                setPlaying('silvio');
              }
            }}/>

          <Button
            color='bg-yellow-400'
            label='Standup'
            isPlaying={playing === 'standup'}
            onClick={() => {
              if (playing) mixer[playing].pause();
              if (playing === 'standup') {
                setPlaying(undefined);
              } else {
                mixer.standup.play();
                setPlaying('standup');
              }
            }}/>
          
          <Button
            color='bg-blue-400'
            label='Esplora'
            isPlaying={playing === 'explore'}
            onClick={() => {
              if (playing) mixer[playing].pause();
              if (playing === 'explore') {
                setPlaying(undefined);
              } else {
                mixer.explore.play();
                setPlaying('explore');
              }
            }}/>

          <Button
            color='bg-red-400'
            label='Problemi'
            isPlaying={playing === 'problems'}
            onClick={() => {
              if (playing) mixer[playing].pause();
              if (playing === 'problems') {
                setPlaying(undefined);
              } else {
                mixer.problems.play();
                setPlaying('problems');
              }
            }}/>

          <Button
            color='bg-green-400'
            label='Release'
            isPlaying={playing === 'release'}
            onClick={() => {
              if (playing) mixer[playing].pause();
              if (playing === 'release') {
                setPlaying(undefined);
              } else {
                mixer.release.play();
                setPlaying('release');
              }
            }}/>

          <Button
            color='bg-purple-400'
            label='Fatture'
            isPlaying={playing === 'invoices'}
            onClick={() => {
              if (playing) mixer[playing].pause();
              if (playing === 'invoices') {
                setPlaying(undefined);
              } else {
                mixer.invoices.play();
                setPlaying('invoices');
              }
            }}/>
        </div>
      </div>
    </div>
  );
}

export default App;

type audios = 'problems' | 'explore' | 'release' | 'invoices' | 'standup' | 'silvio';

const Button: React.FC<{ label: string, color: string, isPlaying: boolean, onClick: () => void }> = 
({ label, color, isPlaying, onClick }) => (
  <button 
    className={clsx('cursor-pointer w-64 h-64 rounded-md text-4xl uppercase', isPlaying && 'blinking-button', color)} 
    onClick={onClick}>
    {isPlaying ? 'Stop' : label}
  </button>
)